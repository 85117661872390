import { AppInfo } from "@mitel/cloudlink-sdk";
import { version } from './version';
// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
  accountsAppUrl: 'https://accounts.mitel.io',
  adminUrl: 'https://admin.api.mitel.io/2017-09-01',
  authUrl: 'https://authentication.api.mitel.io/2017-09-01/token',
  tunnelUrl: 'https://tunnel.api.mitel.io/2017-09-01',
  newTunnelUrl: 'https://tunnel.api.mitel.io/api/v1',
  mivbProvisioningUrl: 'https://mivb.provisioning.api.mitel.io/2017-09-01',
  clientId: '42ade67aa234419486d611fd6825a913',
  cloud: 'public',
  stage: 'live',
  appInfo: new AppInfo({
    correlationIdPrefix: 'cl-gateway-portal-web',
		app: {
		  name: 'cl-gateway-portal-web',
		  version: version.appVersion,
		},
		enableAppHeader: true,
  }),
	googleApiKey: 'AIzaSyAQW4GVQUPvQXB3QmqF9OFfOJlnWzs__GQ'
};
